<template>
  <div class="main">
    
      <v-container>
        <v-row>
          <v-col 
          cols="12"
          md="12">
          <h1 class="title">{{this.event.name}}</h1>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <div class="mainInfo">
              <div class="text">{{this.event.shortDesc}}</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
  </div>
  </template>
<script>


export default {

    async created () {
    const queryuuid = this.$route.query.uuid
    const queryYearMonth = this.$route.query.yearMonth

    const response = await fetch("https://a61zy252h4.execute-api.eu-north-1.amazonaws.com/dev/getEvent"
      + "?date=" + queryYearMonth + "&uuid=" + queryuuid)
    this.event = await response.json();
    console.log(this.event);
  },

  name: 'eventDetails',
  data: () => ({
    uuid: "",
    monthYear: "",
    event: {}
    
  }),

}
</script>

<style>

.title {
  color: white;
  text-align: center;
  font-size: 40px;
}

.main{
height: inherit;
}

.text {
  margin: 10px;
}

.mainWrap {
  width: inherit;
}

.mainInfo {
    background-color: white;
    border-radius: 15px;
    border: #e28 2px solid;
}

.v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
}
/*
.theme--light.v-application {
    background-image: url(https://www.gu.se/sites/default/files/styles/100_10_5_xlarge_1x/public/2020-06/lindholmen-vy_0.jpg?h=5bb25a1b&itok=jPzA-2g9);
}
*/
.v-application .title {
    font-size: 3rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
    -webkit-text-stroke: 1px #e28;
    
}

</style>
