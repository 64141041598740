<template>
  <div class="createEvent">
    <CreateEvent/>
  </div>
</template>

<script>
// @ is an alias to /src
import CreateEvent from '@/components/createEvent.vue'
export default {
  name: 'createEvent',
  components: {
    CreateEvent
  }
}
</script>
