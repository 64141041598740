//export default {
//  region: "eu-north-1",
//  IdentityPoolId: "eu-north-1:6afb6066-2dea-44eb-8aef-589f63eb540e",
//  UserPoolId: "eu-north-1_zNXd7lO2Q",
//  ClientId: "68tnmqnnmh66lp0pp3k9k9d748"
//};

const poolData = { UserPoolId: 'eu-north-1_rYhQfxaUo', ClientId: '4j1latta8nav2jgokqg6d8q8o8' }
export default {
  poolData
}
