





























































import Vue from 'vue'

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),
  created() {
    this.$store.dispatch('tryAutoSignIn')
  },
  computed: {
    getUserName () {
      console.log(this.$store)
      return this.$store.getters.getUserName;
    },
  },

  methods: {
    navAdmin (){
      if(this.getUserName.length > 0) {
        this.$router.push('/createEvent')
      }else {
        this.$router.push('/signIn')
      }
    },

    signOut (){
      this.$store.dispatch('signOut')
    },

    displayName (){
      if(this.getUserName.length > 0) {
        return (this.getUserName.split('@')[0])
      }else{
        return "Kommitté-aktiv"
      }
    }
  }
})
