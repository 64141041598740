<template>
  <div>
    <input type="file" @change="uploadFile" ref="file">
    <button @click="submitFile">Upload!</button>
  </div>
</template>

<script>

import axios from "axios";

export default {
  data: () => ({Documents: null}),

    methods: {
      uploadFile() {
        this.Documents = this.$refs.file.files[0];
        console.log(this.$refs.file.files[0]);
        //console.log(this.$store.getters.getIdentityToken);
      },
      async submitFile() {
        const formData = new FormData();
        formData.append('file', this.Documents);
        formData.append('name', this.Documents.name);
        const headers = { 
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.$store.getters.getIdentityToken 
        };
        axios.post('https://a61zy252h4.execute-api.eu-north-1.amazonaws.com/dev/uploadDocument', formData, headers).then((res) => {
          //console.log(res);
          res.data.files; // binary representation of the file
          res.status; // HTTP status
        });
      }
      

      



    },
    /*
    submitFile() {
      const formData = new FormData();
      formData.append('file', this.Documents);
      formData.append('name', this.Documents.name);
      const headers = { 
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + this.$store.getters.getIdentityToken 
      };
      axios.post('https://httpbin.org/post', formData, { headers }).then((res) => {
        console.log(res);
        res.data.files; // binary representation of the file
        res.status; // HTTP status
      });
    }
    */
  }
  
  
</script>

<style>

</style>
