<template>
  <div class="uploadDocument">
    <UploadDocument/>
  </div>
</template>

<script>
// @ is an alias to /src
import UploadDocument from '@/components/uploadDocument.vue'
export default {
  name: 'uploadDocument',
  components: {
    UploadDocument
  }
}
</script>
