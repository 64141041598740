<template>
  <div class="forgot">
    <forgot/>
  </div>
</template>

<script>
// @ is an alias to /src
import forgot from '@/components/auth/forgot.vue'
export default {
  name: 'Forgot',
  components: {
    forgot
  }
}
</script>

<style>

</style>
